import axios from 'axios'

export async function downloadFile(url, name) {
  const response = await axios.get(url, {
    responseType: 'blob',
  })
  const objectURL = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
  const a = document.createElement('a')
  a.href = objectURL
  if (name) {
    a.download = name
  }
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(objectURL)
  a.remove()
}
