<template>
  <v-dialog v-model="show" scrollable persistent max-width="800px">
    <v-card>
      <v-card-title v-if="!product.id" class="headline grey lighten-2" primary-title>Новый товар</v-card-title>
      <v-card-title v-else class="headline grey lighten-2" primary-title>Редактирование товара</v-card-title>
      <v-card-text ref="card">
        <v-form ref="form" v-model="valid">
          <div class="mb-4">
            <v-container grid-list-md text-xs-center>
              <v-layout align-center>
                <v-flex xs2>
                  <v-btn v-if="galleryIndex > 0" flat icon large color="grey" @click="prevImg">
                    <v-icon x-large>navigate_before</v-icon>
                  </v-btn></v-flex
                >
                <v-flex xs8>
                  <div v-if="galleryIndex < galleryFiles.length" class="img">
                    <v-img :src="galleryFiles[galleryIndex]" height="200" contain aspect-ratio="2"></v-img>
                    <v-btn flat icon small @click="copyImageLink" color="info" class="img-copy-button">
                      <v-icon>link</v-icon>
                    </v-btn>
                    <v-btn flat icon small @click="deleteImage" color="error" class="img-del-button">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="img-placeholder">
                    <v-btn fab dark color="grey" @click="openGalleryFileDialog">
                      <v-icon dark>add</v-icon>
                    </v-btn>
                    <input v-show="false" ref="gallery-file" type="file" accept="image/*" @change="addImage($event)" />
                  </div>
                </v-flex>
                <v-flex xs2>
                  <v-btn v-if="galleryIndex < galleryFiles.length" flat icon large color="grey" @click="nextImg">
                    <v-icon x-large>navigate_next</v-icon>
                  </v-btn></v-flex
                >
              </v-layout>
            </v-container>
          </div>
          <v-text-field
            v-model="product.name"
            :rules="[rules.required]"
            box
            label="Название товара"
            required
          ></v-text-field>
          <v-text-field v-model="product.report_name" box label="Название для отчетов" required></v-text-field>
          <v-text-field v-model="product.type" :rules="[rules.required]" box label="Тип" required></v-text-field>
          <category-autocomplete-multiple v-model="categories" :box="true" />
          <v-text-field
            v-model="product.original_vendor_code"
            :rules="[rules.required]"
            box
            label="Артикул"
            required
          ></v-text-field>
          <v-select
            v-model="product.brand_id"
            :items="brands"
            box
            label="Бренд"
            item-text="title"
            item-value="id"
            required
          ></v-select>
          <v-text-field v-model="product.color" box label="Цвет" required></v-text-field>
          <v-text-field v-model="product.legal_manufacturer_title" box label="Производитель"></v-text-field>
          <v-text-field v-model="product.legal_supplier_title" box label="Поставщик"></v-text-field>
          <v-textarea v-model="product.description" box label="Описание" rows="5"></v-textarea>
          <div class="subheading grey--text mb-2">Цена</div>
          <v-text-field
            v-model="product.cost_price"
            :rules="[rules.required]"
            box
            :label="`Себестоимость, ${currency}`"
            type="number"
            required
          ></v-text-field>
          <v-text-field
            v-model="product.full_cost_price"
            box
            :label="`Себестоимость без скидки, ${currency}`"
            type="number"
          ></v-text-field>
          <v-text-field v-model="product.priority" box label="Приоритет" type="number"></v-text-field>
          <div class="subheading grey--text mb-2">Индивидуальная наценка</div>
          <v-container grid-list-md class="pl-0 pr-0 pt-0">
            <v-layout>
              <v-flex xs>
                <v-checkbox v-model="product.is_charge_manually" type="checkbox" color="info" required></v-checkbox>
              </v-flex>
              <v-flex xs7>
                <v-text-field
                  v-model="product.charge"
                  :disabled="!product.is_charge_manually"
                  box
                  label="Наценка"
                  type="number"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="product.charge_type"
                  :items="CHARGE_TYPE"
                  :disabled="!product.is_charge_manually"
                  box
                  label="Тип"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="subheading grey--text mb-2">Размер товара, см</div>
          <v-container grid-list-md class="pl-0 pr-0 pt-0">
            <v-layout>
              <v-flex xs3>
                <v-text-field v-model="product.width" box label="Ширина" type="number" required></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="product.depth" box label="Глубина" type="number" required></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="product.height" box label="Высота" type="number" required></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="product.length" box label="Длина" type="number" required></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs3>
                <v-text-field v-model="product.thickness" box label="Толщина" type="number" required></v-text-field>
              </v-flex>
              <v-flex xs9>
                <v-text-field v-model="product.other_sizes" box label="Другие размеры" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="subheading grey--text mb-2">Характеристики</div>
          <v-textarea v-model="product.benefit" box label="Полезная информация" rows="4" required></v-textarea>
          <v-textarea v-model="product.sold_separately" box label="Продается отдельно" rows="4" required></v-textarea>
          <v-textarea v-model="product.cust_materials" box label="Материал" rows="4" required></v-textarea>
          <v-select v-model="product.measurement_unit" :items="UNIT" box label="Единица измерения" required></v-select>
          <v-select v-model="product.vat_rate" :items="VAT" box label="Ставка НДС" required></v-select>
          <v-text-field v-model="product.expiration_period" box label="Срок годности" required></v-text-field>
          <v-text-field v-model="product.origin_country" box label="Страна происхождения" required></v-text-field>
          <v-text-field v-model="product.barcode" box label="Штрихкод" required></v-text-field>

          <div class="subheading grey--text mb-2">Гарантия</div>
          <v-layout>
            <v-flex xs1>
              <v-checkbox v-model="product.is_guarantee" type="checkbox" color="info" required></v-checkbox>
            </v-flex>
            <v-flex xs11>
              <v-text-field v-model="product.guarantee_period" box label="Гарантийный срок" required></v-text-field>
            </v-flex>
          </v-layout>

          <div class="subheading grey--text mb-2">Сборка</div>
          <div v-for="(file, index) of assemblyFiles" :key="`assembly-${index}`">
            <a :href="file.src" target="_blank">{{ file.name }}</a>
            <v-btn flat icon small @click="copyAssemblyDocumentLink(index)" color="info" class="mr-1">
              <v-icon>link</v-icon>
            </v-btn>
            <v-btn flat icon small @click="deleteAssemblyDocument(index)" color="error" class="ml-0">
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
          <v-btn flat icon small color="info" class="ml-0 mt-0 mb-4" @click="openAssemblyFileDialog">
            <v-icon>add_circle</v-icon>
          </v-btn>
          <input v-show="false" ref="assembly-file" type="file" @change="addAssemblyDocument($event)" />
          <div class="subheading grey--text mb-2">Другие документы</div>
          <div v-for="(file, index) of otherFiles" :key="`other-${index}`">
            <a :href="file.src" target="_blank">{{ file.name }}</a>
            <v-btn flat icon small @click="copyOtherDocumentLink(index)" color="info" class="mr-1">
              <v-icon>link</v-icon>
            </v-btn>
            <v-btn flat icon small @click="deleteOtherDocument(index)" color="error">
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
          <v-btn flat icon small color="info" class="ml-0 mt-0 mb-4" @click="openOtherFileDialog">
            <v-icon>add_circle</v-icon>
          </v-btn>
          <input v-show="false" ref="other-file" type="file" @change="addOtherDocument($event)" />
          <div class="subheading grey--text mb-2">Упаковка</div>
          <v-data-table :headers="pkgHeaders" :items="packs" hide-actions disable-initial-sort>
            <template v-slot:items="props">
              <td>
                <v-edit-dialog :return-value.sync="props.item.articleNumber" lazy>
                  {{ props.item.articleNumber }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.articleNumber" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.quantity" lazy>
                  {{ props.item.quantity }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.quantity" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.pkgInfo.widthMet" lazy>
                  {{ props.item.pkgInfo.widthMet }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.pkgInfo.widthMet" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.pkgInfo.heightMet" lazy>
                  {{ props.item.pkgInfo.heightMet }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.pkgInfo.heightMet" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.pkgInfo.lengthMet" lazy>
                  {{ props.item.pkgInfo.lengthMet }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.pkgInfo.lengthMet" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.pkgInfo.weightMet" lazy>
                  {{ props.item.pkgInfo.weightMet }}
                  <template v-slot:input>
                    <v-text-field v-model="props.item.pkgInfo.weightMet" single-line></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-btn flat icon small @click="deletePackItem(props.item)" color="error">
                  <v-icon>clear</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
          <div class="text-xs-center">
            <v-btn flat icon small color="info" class="mt-0 mb-4" @click="addPackItem">
              <v-icon>add_circle</v-icon>
            </v-btn>
          </div>
          <div class="subheading grey--text mb-2">Сопутствующие товары</div>
          <v-textarea
            v-model="additionalProducts"
            box
            label="Укажите список артикулов через запятую"
            rows="4"
            required
          ></v-textarea>
          <div class="subheading grey--text mb-2">Похожие товары</div>
          <v-textarea
            v-model="similarProducts"
            box
            label="Укажите список артикулов через запятую"
            rows="4"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="close" outline>Закрыть</v-btn>
        <v-btn v-if="!product.id" color="info" @click="create" :loading="loading">Создать</v-btn>
        <v-btn v-else color="info" @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="showCopied" color="info" :top="true" :timeout="3000" :value="false">
      {{ copiedText }}
      <v-btn dark flat @click="showCopied = false">Закрыть</v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { CHARGE_TYPE } from '@/constants.js'
import CategoryAutocompleteMultiple from '@/components/inputs/CategoryAutocompleteMultiple'

const VAT = [
  { text: 'без НДС', value: 'NO_VAT' },
  { text: '0%', value: 'VAT_0' },
  { text: '10%', value: 'VAT_10' },
  { text: '20%', value: 'VAT_20' },
]

const UNIT = [
  { text: 'шт.', value: 'шт.' },
  { text: 'м', value: 'м' },
  { text: 'м2', value: 'м2' },
  { text: 'м3', value: 'м3' },
  { text: 'л', value: 'л' },
]

export default {
  components: { CategoryAutocompleteMultiple },

  props: {
    product: Object,
    currency: String,
    show: Boolean,
  },

  data: () => ({
    loading: false,
    pkgHeaders: [
      { text: 'Артикул', sortable: false },
      { text: 'Кол-во', sortable: false },
      { text: 'Ширина, см', sortable: false, class: 'multiline' },
      { text: 'Высота, см', sortable: false, class: 'multiline' },
      { text: 'Длина, см', sortable: false, class: 'multiline' },
      { text: 'Вес, кг', sortable: false, class: 'multiline' },
      { text: 'Действия', sortable: false },
    ],
    VAT: VAT,
    UNIT: UNIT,
    CHARGE_TYPE: CHARGE_TYPE,
    supplierId: null,
    galleryIndex: 0,
    galleryFiles: [],
    assemblyFiles: [],
    otherFiles: [],
    additionalProducts: '',
    similarProducts: '',
    packs: [],
    valid: true,
    rules: {
      required: (value) => !!value || 'Обязательное поле',
    },
    showCopied: false,
    copiedText: '',
    categories: [],
  }),

  watch: {
    show() {
      this.$nextTick(() => {
        this.$refs.card.scrollTop = 0
        this.$refs.form.resetValidation()
      })

      this.galleryIndex = 0
      this.galleryFiles = []
      this.assemblyFiles = []
      this.otherFiles = []
      this.packs = []

      this.additionalProducts = ''
      if (this.product.additional_products) {
        this.additionalProducts = this.product.additional_products.join(', ')
      }

      this.similarProducts = ''
      if (this.product.similar_products) {
        this.similarProducts = this.product.similar_products.join(', ')
      }

      if (this.product?.gallery?.large) {
        for (const src of this.product?.gallery?.large) {
          this.galleryFiles.push(src)
        }
      }

      if (this.product.pkg_info && Array.isArray(this.product.pkg_info)) {
        for (const p of this.product.pkg_info) {
          this.packs.push(p)
        }
      }

      if (this.product?.attachments?.[0]?.atcharray) {
        for (const doc of this.product.attachments[0].atcharray) {
          this.assemblyFiles.push({ src: doc.attachmentPath, name: doc.attachmentName })
        }
      }

      if (this.product?.attachments?.[1]?.atcharray) {
        for (const doc of this.product.attachments[1].atcharray) {
          this.otherFiles.push({ src: doc.attachmentPath, name: doc.attachmentName })
        }
      }
    },
    product() {
      this.categories = this.product.categories.map((cat) => cat.id)
    },
  },

  computed: {
    ...mapState({ brands: (state) => state.brands.brands }),
  },

  async mounted() {
    this.supplierId = this.$route.params.id
  },

  methods: {
    close() {
      this.$emit('close')
    },

    createData() {
      const formData = new FormData()
      formData.append('name', this.check(this.product.name))
      formData.append('report_name', this.check(this.product.report_name))
      formData.append('type', this.check(this.product.type))

      for (let [i, cat] of this.categories.entries()) {
        formData.append(`categories[${i}]`, cat)
      }

      formData.append('original_vendor_code', this.check(this.product.original_vendor_code))
      formData.append('brand_id', this.check(this.product.brand_id))
      formData.append('color', this.check(this.product.color))
      formData.append('legal_manufacturer_title', this.check(this.product.legal_manufacturer_title))
      formData.append('legal_supplier_title', this.check(this.product.legal_supplier_title))
      formData.append('description', this.check(this.product.description))
      formData.append('width', this.check(this.product.width))
      formData.append('depth', this.check(this.product.depth))
      formData.append('height', this.check(this.product.height))
      formData.append('length', this.check(this.product.length))
      formData.append('thickness', this.check(this.product.thickness))
      formData.append('other_sizes', this.check(this.product.other_sizes))
      formData.append('benefit', this.check(this.product.benefit))
      formData.append('sold_separately', this.check(this.product.sold_separately))
      formData.append('cust_materials', this.check(this.product.cust_materials))
      formData.append('measurement_unit', this.check(this.product.measurement_unit))
      formData.append('vat_rate', this.check(this.product.vat_rate))
      formData.append('expiration_period', this.check(this.product.expiration_period))
      formData.append('origin_country', this.check(this.product.origin_country))
      formData.append('barcode', this.check(this.product.barcode))
      formData.append('cost_price', this.check(this.product.cost_price))
      formData.append('full_cost_price', this.check(this.product.full_cost_price))
      formData.append('priority', this.check(this.product.priority))
      formData.append('is_guarantee', this.product.is_guarantee ? 1 : 0)
      formData.append('guarantee_period', this.check(this.product.guarantee_period))

      formData.append('is_charge_manually', this.product.is_charge_manually ? 1 : 0)
      if (this.product.is_charge_manually) {
        formData.append('charge', this.check(this.product.charge))
        formData.append('charge_type', this.check(this.product.charge_type))
      }

      for (let [i, p] of this.packs.entries()) {
        formData.append(`pkg_info[${i}][articleNumber]`, this.check(p.articleNumber))
        formData.append(`pkg_info[${i}][quantity]`, this.check(p.quantity))
        formData.append(`pkg_info[${i}][pkgInfo][widthMet]`, this.check(p.pkgInfo.widthMet))
        formData.append(`pkg_info[${i}][pkgInfo][heightMet]`, this.check(p.pkgInfo.heightMet))
        formData.append(`pkg_info[${i}][pkgInfo][lengthMet]`, this.check(p.pkgInfo.lengthMet))
        formData.append(`pkg_info[${i}][pkgInfo][weightMet]`, this.check(p.pkgInfo.weightMet))
      }

      if (this.additionalProducts) {
        const additionalProducts = this.additionalProducts.split(',').map((p) => p.trim())
        for (let [i, ap] of additionalProducts.entries()) {
          formData.append(`additional_products[${i}]`, ap)
        }
      }

      if (this.similarProducts) {
        const similarProducts = this.similarProducts.split(',').map((p) => p.trim())
        for (let [i, ap] of similarProducts.entries()) {
          formData.append(`similar_products[${i}]`, ap)
        }
      }

      if (this.galleryFiles.length > 0) {
        for (let i = 0; i < this.galleryFiles.length; i++) {
          formData.append(`gallery[${i}]`, this.galleryFiles[i])
        }
      } else {
        formData.append('gallery[0]', '')
      }

      if (this.assemblyFiles.length > 0) {
        for (let [i, f] of this.assemblyFiles.entries()) {
          formData.append(`instructions_attachments[${i}]`, f.src)
        }
      } else {
        formData.append('instructions_attachments[0]', '')
      }

      if (this.otherFiles.length > 0) {
        for (let [i, f] of this.otherFiles.entries()) {
          formData.append(`other_attachments[${i}]`, f.src)
        }
      } else {
        formData.append('other_attachments[0]', '')
      }

      return formData
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.axios.post(
            `/api/admin/suppliers/${this.supplierId}/products/${this.product.id}`,
            this.createData()
          )
          this.$emit('save')
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },

    async create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.axios.post(`/api/admin/suppliers/${this.supplierId}/products`, this.createData())
          this.$emit('save')
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },

    async addImage(event) {
      try {
        const formData = new FormData()
        formData.append('path', 'products/images')
        formData.append('useMd5Nesting', 1)
        formData.append('file', event.target.files[0], event.target.files[0].name)
        const response = await this.axios.post(`/api/admin/suppliers/${this.supplierId}/files`, formData)
        this.galleryFiles.push(response.data.data)
      } catch (e) {
        console.log(e)
      }
    },

    async deleteImage() {
      try {
        await this.axios.delete(`/api/admin/suppliers/${this.supplierId}/files`, {
          data: { file: this.galleryFiles[this.galleryIndex] },
        })
        this.galleryFiles.splice(this.galleryIndex, 1)
        if (this.galleryIndex > 0) {
          this.galleryIndex--
        }
      } catch (e) {
        console.log(e)
      }
    },

    copyImageLink() {
      navigator.clipboard.writeText(this.galleryFiles[this.galleryIndex])
      this.copiedText = 'Ссылка на картинку скопирована'
      this.showCopied = true
    },

    async addAssemblyDocument(event) {
      try {
        const formData = new FormData()
        formData.append('path', 'products/attachments')
        formData.append('useMd5Nesting', 1)
        formData.append('useOriginalFileName', 1)
        formData.append('file', event.target.files[0], event.target.files[0].name)
        const response = await this.axios.post(`/api/admin/suppliers/${this.supplierId}/files`, formData)
        this.assemblyFiles.push({ name: event.target.files[0].name, src: response.data.data })
      } catch (e) {
        console.log(e)
      }
    },

    async deleteAssemblyDocument(index) {
      try {
        await this.axios.delete(`/api/admin/suppliers/${this.supplierId}/files`, {
          data: { file: this.assemblyFiles[index].src },
        })
        this.assemblyFiles.splice(index, 1)
      } catch (e) {
        console.log(e)
      }
    },

    copyAssemblyDocumentLink(index) {
      navigator.clipboard.writeText(this.assemblyFiles[index].src)
      this.copiedText = 'Ссылка на документ скопирована'
      this.showCopied = true
    },

    async addOtherDocument(event) {
      try {
        const formData = new FormData()
        formData.append('path', 'products/attachments')
        formData.append('useMd5Nesting', 1)
        formData.append('useOriginalFileName', 1)
        formData.append('file', event.target.files[0], event.target.files[0].name)
        const response = await this.axios.post(`/api/admin/suppliers/${this.supplierId}/files`, formData)
        this.otherFiles.push({ name: event.target.files[0].name, src: response.data.data })
      } catch (e) {
        console.log(e)
      }
    },

    async deleteOtherDocument(index) {
      try {
        await this.axios.delete(`/api/admin/suppliers/${this.supplierId}/files`, {
          data: { file: this.otherFiles[index].src },
        })
        this.otherFiles.splice(index, 1)
      } catch (e) {
        console.log(e)
      }
    },

    copyOtherDocumentLink(index) {
      navigator.clipboard.writeText(this.otherFiles[index].src)
      this.copiedText = 'Ссылка на документ скопирована'
      this.showCopied = true
    },

    openGalleryFileDialog() {
      this.$refs['gallery-file'].value = ''
      this.$refs['gallery-file'].click()
    },

    openAssemblyFileDialog() {
      this.$refs['assembly-file'].value = ''
      this.$refs['assembly-file'].click()
    },

    openOtherFileDialog() {
      this.$refs['other-file'].value = ''
      this.$refs['other-file'].click()
    },

    nextImg() {
      this.galleryIndex++
    },

    prevImg() {
      this.galleryIndex--
    },

    check(value) {
      return value === null || value === undefined ? '' : value
    },

    deletePackItem(item) {
      const index = this.packs.indexOf(item)
      this.packs.splice(index, 1)
    },

    addPackItem() {
      this.packs.push({
        articleNumber: '',
        quantity: '',
        pkgInfo: { widthMet: '', heightMet: '', lengthMet: '', weightMet: '' },
      })
    },
  },
}
</script>

<style scoped>
.img {
  position: relative;
}

.img-placeholder {
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.img-del-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}

.img-copy-button {
  position: absolute !important;
  top: 0;
  right: 35px;
  z-index: 1;
}
</style>
